import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_URL}api/v1/`; // Replace with your API base URL
// console.log("BASE_URL", BASE_URL);

const getCookie = (name, defaultValue = "") => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return defaultValue;
};
const token =
  getCookie("authToken") ||
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTY1Mjc1NzEsImlhdCI6MTcxNjQ0MTE3MSwianRpIjoiNDRkNWI4MTJmNDk3MWRlZGM4ODc0YjI4N2Q0MjE1ODQiLCJ1c2VybmFtZSI6ImFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJjbG91ZFVzZXJJZCI6Miwicm9sZSI6eyJpZCI6MiwibmFtZSI6IkFkbWluaXN0cmF0b3IiLCJrZXkiOiJhZG1pbiJ9fQ.kyckq6ztmdEdSRXxoVuP3imA4Qf6Nh2WT2lveO6Sktg";

// Example of a GET request
export const getRequest = async (route) => {
  try {
    let config = {
      method: "get",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
    };
    // console.log("token", token);

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Example of a POST request
export const postRequest = async (route, data) => {
  try {
    let config = {
      method: "post",
      url: `${BASE_URL}${route}`,
      headers: {
        authToken: token,
      },
      //   data: JSON.stringify(data),
      data: data,
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw { message: "Something went wrong" };
  }
};

// Example of a PUT request
export const putRequest = async (route, data) => {
  try {
    let config = {
      method: "put",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
      data: JSON.stringify(data),
    };

    if (localStorage.getItem("token")) {
      console.log("token", token);
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error putting data:", error);
    throw error;
  }
};

// Example of a DELETE request
export const deleteRequest = async (route) => {
  try {
    let config = {
      method: "delete",
      url: `${BASE_URL}${route}`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      headers: {
        authToken: token,
      },
    };

    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

// api-service.js

export const fetchCoins = async (payload) => {
  const { data } = await axios.post("https://api.silocloud.io/recently-added", payload);
  return data;
};

// export default api;
