import React, { useState, createContext } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../components/Component";
import Head from "../../layout/head/Head";
import ContactForm from "./ContactForm";
import ConsultingForm from "./ConsultingForm";

const FormContext = createContext();

const UserEvent = () => {
  const [activeForm, setActiveForm] = useState(0);

  const formComponents = [
    <ContactForm key="contact" />,
    <ConsultingForm key="consulting" />,
  ];

  const buttons = [
    { title: "Contact Us", label: "Contacts" },
    { title: "Request", label: "Consultation" },
  ];

  return (
    <React.Fragment>
      <Head title="User Management" />
      <Content page="component">
        <Block size="lg" className="user-list-table">
          <BlockHead className="d-flex">
            <BlockHeadContent>
              <BlockTitle tag="h3">Feedback And Request</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className="ms-auto">
              {buttons.map((btn, index) => (
                <button
                  key={btn.title}
                  onClick={() => setActiveForm(index)}
                  className={`btn mx-1 my-1 tooltip_toggler ${activeForm === index ? "bg-danger text-white" : "bg-white text-danger"
                    }`}
                  title={btn.label}
                >
                  {btn.title}
                </button>
              ))}
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <FormContext.Provider value={activeForm}>
              {formComponents[activeForm]}
            </FormContext.Provider>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default UserEvent;
export { FormContext };